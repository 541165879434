<template>
  <div v-if="currentUser" :class="[{ 'bg-white py-3 px-5': !isNotOpenInModal }]">
    <div class="md:mx-0 mt-5 bg-white rounded-lg py-6 px-5 text-xs md:text-base">
      <div class="pb-5 mb-5 border-b border-primary-gray-200">
        <div
          :class="[
            {
              'bg-primary-white p-3 md:mx-0  w-full mr-5 border flex flex-row border-primary-grey rounded-lg':
                isNotOpenInModal,
            },
          ]"
        >
          <div class="w-inherit">
            <div class="flex gap-3 mt-3 flex-col md:flex-row">
              <div class="flex flex-1 gap-2">
                <div class="font-bold w-21.5 md:w-28.5">
                  <span v-i18n="title">Name</span>
                  <span>:</span>
                </div>
                <div class="text-primary-grey-light capitalize">
                  {{ currentUser?.user_details?.full_name || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </div>
              </div>
              <div class="flex flex-1 gap-2">
                <div class="font-bold w-21.5 md:w-28.5">
                  <span v-i18n="title">Gender</span>
                  <span>:</span>
                </div>
                <div class="text-primary-grey-light capitalize">
                  {{ currentUser?.user_details?.gender || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </div>
              </div>
            </div>
            <div class="flex gap-3 mt-3 flex-col md:flex-row flex-wrap lg:mt-5">
              <div class="flex flex-1 gap-2">
                <div class="font-bold w-21.5 md:w-28.5">
                  <span v-i18n="title">DOB</span>
                  <span>:</span>
                </div>
                <div class="text-primary-grey-light">
                  {{
                    currentUser?.user_details?.profile &&
                    currentUser?.user_details?.profile?.date_of_birth
                      ? currentUser?.user_details?.profile.date_of_birth
                      : GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </div>
              </div>
              <div class="flex flex-1 gap-2">
                <div class="font-bold w-21.5 md:w-28.5">
                  <span v-i18n="title">Phone Number</span>
                  <span>:</span>
                </div>
                <div class="text-primary-grey-light">
                  {{ currentUser?.user_details?.phone || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </div>
              </div>
            </div>
            <div class="flex gap-3 mt-3 flex-col md:flex-row flex-wrap lg:mt-5">
              <div class="flex flex-1 gap-2">
                <div class="font-bold w-21.5 md:w-28.5">
                  <span v-i18n="title">Email</span>
                  <span>:</span>
                </div>
                <div class="text-primary-grey-light">
                  {{ currentUser?.user_details?.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-for="(entity, ind) in detailedList" :key="ind" class="flex flex-col gap-2">
        <div v-if="entity.records.length" :class="getBorderClass(ind)">
          <div v-if="entity.records.length" :class="[{ 'bg-primary-white': isNotOpenInModal }]">
            <div>
              <div>
                <div>
                  <span
                    class="w-full text-sm font-medium text-primary-gray-700 block rtl:pl-3 ltr:pr-3"
                  >
                    {{ $t(`title.${entity.title}`) }}
                  </span>
                </div>
                <div>
                  <span
                    class="w-full text-sm font-normal text-primary-gray-500 block rtl:pl-3 ltr:pr-3"
                  >
                    {{ entity.description }}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <span>
                <div v-if="entity.records && entity.records.length" :class="entity.class && 'my-2'">
                  <div v-if="entity.class">
                    <div v-for="(entityRecord, id) in entity.records" :key="id">
                      <div class="rounded-lg border border-primary-gray-200 p-5 overflow-x-auto'">
                        <div
                          class="w-full text-sm text-primary-gray-700 font-semibold flex items-center border-b pb-5 border-primary-gray-200"
                        >
                          <div>
                            {{ entityRecord.campus.title }}
                          </div>
                          <div class="ml-auto">
                            <Pagination
                              v-if="entity.pagination"
                              :key="`Pagination${forceRender}`"
                              :record-limit="maxRecordInOuterPagination"
                              :max-range="
                                paginationCounts(entity.recordsCount, maxRecordInOuterPagination)
                              "
                              @filterRecord="entity.getRecords"
                            />
                          </div>
                        </div>
                        <TableWrapper v-if="entityRecord.classes_sections.length">
                          <THead>
                            <TRHead class="text-center">
                              <TH v-for="head in entity.tableHead" :key="head">
                                <span>
                                  {{ head }}
                                </span>
                              </TH>
                            </TRHead>
                          </THead>
                          <TBody>
                            <TRBody
                              v-for="(classSection, index) in entityRecord.classes_sections"
                              :key="index"
                            >
                              <TD
                                v-for="(item, key) in Object.entries(classSection).slice(0, 2)"
                                :key="key"
                              >
                                <div
                                  v-if="
                                    [userRoles.GUARDIAN_OF, userRoles.PICKUP_PERSON_OF].includes(
                                      entity.title,
                                    ) && entity.chipFlag.includes(item[0])
                                  "
                                >
                                  <BadgeDisplay
                                    :options="item[1]"
                                    :dynamic-chip-width="true"
                                    :dropdown-top-bottom="
                                      dropdownTopBottom(index, entity.records, 'bottom-6', 'top-6')
                                    "
                                    :tooltip="
                                      dropdownTopBottom(
                                        index,
                                        entity.records,
                                        'tooltip-bottom',
                                        'tooltip-top',
                                      )
                                    "
                                  />
                                </div>
                                <span v-else>
                                  {{ item[1] || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                                </span>
                              </TD>

                              <TD>
                                <button
                                  class="cursor-pointer rounded-lg bg-primary-purple-50 text-sm font-semibold text-primary-purple-700 py-2.5 px-4"
                                  @click="
                                    accessActions(
                                      setActionList(entity.title),
                                      classSection,
                                      index,
                                      entity.title,
                                      entity.records.length,
                                    )
                                  "
                                >
                                  {{ $t(`options.${setActionList(entity.title)}`) }}
                                </button>
                              </TD>
                            </TRBody>
                          </TBody>
                        </TableWrapper>
                      </div>
                      <Pagination
                        v-if="entityRecord.pagination"
                        :key="`Pagination${forceRenderSectionPagination}`"
                        :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
                        :max-range="
                          paginationCounts(
                            entityRecord.recordsCount,
                            GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
                          )
                        "
                        @filterRecord="
                          (range) => {
                            entity.getSectionRecords(range, entityRecord?.campus?.pagination_id)
                          }
                        "
                      />
                    </div>
                  </div>

                  <div v-else>
                    <div>
                      <TableWrapper>
                        <THead>
                          <TRHead>
                            <TH v-for="head in entity.tableHead" :key="head">
                              <span>
                                {{ head }}
                              </span>
                            </TH>
                          </TRHead>
                        </THead>
                        <TBody>
                          <TRBody v-for="(accessRecord, index) in entity.records" :key="index">
                            <TD
                              v-for="(item, key) in filteredEntries(accessRecord).slice(0, 2)"
                              :key="key"
                            >
                              <span v-if="item[0] === 'role'">
                                <UiStatus
                                  :key="`Status${forceRenderer}`"
                                  :title="item[1] || GENERAL_CONSTANTS.NOT_APPLICABLE"
                                  :is-user="true"
                                />
                              </span>
                              <span v-else>
                                {{ item[1] || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                              </span>
                            </TD>

                            <TD>
                              <button
                                class="cursor-pointer rounded-lg bg-primary-purple-50 text-sm font-semibold text-primary-purple-700 py-2.5 px-4"
                                @click="
                                  accessActions(
                                    setActionList(entity.title),
                                    accessRecord,
                                    index,
                                    entity.title,
                                    entity.records.length,
                                  )
                                "
                              >
                                {{ $t(`options.${setActionList(entity.title)}`) }}
                              </button>
                            </TD>
                          </TRBody>
                        </TBody>
                      </TableWrapper>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div v-if="!entity.class">
            <Pagination
              v-if="entity.pagination"
              :key="`Pagination${forceRender}`"
              :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
              :max-range="
                paginationCounts(entity.recordsCount, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
              "
              @filterRecord="entity.getRecords"
            />
          </div>
        </div>
      </div>
    </div>
    <UserDeactivateModal @success="revokeEnitityHandler" />
    <UIConfirmationModal
      v-if="isOpen"
      heading="REVOKE_ACESS"
      :message="confirmationMessage"
      button-text="Revoke"
      :modal="isOpen"
      @cancel="toggleRevokeAccessConfirmationModal"
      @confirm="revokeRoleAccess"
    />
  </div>
</template>

<script>
import Pagination from '@components/BaseComponent/Pagination.vue'
import { mapState } from 'vuex'
import UserDeactivateModal from '@/src/components/action-components/DeactivateModal.vue'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import { mapActions } from 'vuex'
import { objectDeepCopy } from '@utils/generalUtil'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import userRoles from '@src/constants/user-roles-constants.js'
import { dropdownTopBottom } from '@src/utils/generalUtil.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import {
  TableWrapper,
  THead,
  TRHead,
  TH,
  TBody,
  TRBody,
  TD,
} from '@src/components/UiElements/TableElements/index.js'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'

export default {
  components: {
    Pagination,
    Loader,
    UiStatus,
    UserDeactivateModal,
    UIConfirmationModal,
    BadgeDisplay,
    TableWrapper,
    THead,
    TRHead,
    TH,
    TBody,
    TRBody,
    TD,
  },
  mixins: [generalUtil],
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    isNotOpenInModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      title: 'title',
      forceRender: 0,
      forceRenderer: 0,
      forceRenderSectionPagination: 0,
      detailedList: [],
      isOpen: false,
      entityRecordsCount: 0,
      editedList: [],
      currentUser: {},
      isLoading: false,
      selectedUser: '',
      maxRecordInOuterPagination: 1,
      selectedRole: '',
      userRoles,
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state?.layout?.currentCampusScope,
      instituteName: (state) => state.settings.generalSettingInstituteName,
    }),
    isCampusAdmin() {
      return !!this.currentUser?.user_roles_details?.campus_admin_details?.records?.length
    },
    isStaff() {
      return !!this.currentUser?.user_roles_details?.staff_details?.records?.length
    },
    actionList() {
      return this.currentUser?.user_details?.is_revocable
        ? { name: 'REVOKE_ACCESS' }
        : { name: 'TV_DEACTIVATE_USER' }
    },
    associatedActionList() {
      return this.currentUser?.user_details.is_revocable ||
        this.currentUser?.user_details?.has_associations
        ? { name: 'REVOKE_ACCESS' }
        : { name: 'TV_DEACTIVATE_USER' }
    },
    confirmationMessage() {
      let message = this.selectedUser?.unassociated_id
        ? `Are you sure, you want to revoke the association with ${this.selectedUser?.student} ? `
        : this.selectedUser?.role === userRoles.SUPER_ADMIN
        ? `Are you sure, you want to revoke the Super Admin access ?`
        : `Are you sure, you want to revoke the access from ${
            this.selectedUser?.section || this.selectedUser?.campus || this.selectedUser?.title
          } ? `
      let result
      if (this.entityRecordsCount === 1)
        result = `This is the final permission of this role. ${message}`
      else result = message
      return result
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(value) {
        this.selectedUser = ''
        this.detailedList = []
        this.editedList = []
        this.roleDetail()
      },
      deep: true,
    },
    detailedList: {
      immediate: true,
      deep: true,
      handler(value) {
        this.forceRenderer++
      },
    },
  },
  methods: {
    setActionList(entity) {
      return [userRoles.MY_GUARDIAN, userRoles.MY_PICKUP_PERSON].includes(entity)
        ? this.associatedActionList.name
        : this.actionList.name
    },
    dropdownTopBottom,
    accessActions(action, current, id, role, totalRecordsCount) {
      this.selectedUser = current
      this.selectedRole = role
      this.editedList = this.detailedList
      this.entityRecordsCount = totalRecordsCount
      switch (action) {
        case 'REVOKE_ACCESS':
          this.toggleRevokeAccessConfirmationModal()
          break
        case 'TV_DEACTIVATE_USER':
          this.$store.commit('layout/SET_CURRENT_SELECTED_USER', this.currentUser?.user_details)
          this.$store.commit('layout/SET_SHOW_DEACTIVATE_MODAL', true)
          break
      }
    },
    unAssociateStudent() {
      if (
        this.selectedRole === userRoles.PICKUP_PERSON_OF ||
        this.selectedRole === userRoles.MY_PICKUP_PERSON
      ) {
        let payload = {
          student_info_id: this.selectedUser?.id,
          pickup_person_info_id: this.selectedUser?.unassociated_id,
        }

        this.unAssosiateStudentsWithPickupMembers(payload).then((res) => {
          this.revokeEnitityHandler()
          this.forceRender++
          this.forceRenderSectionPagination++
          this.toggleRevokeAccessConfirmationModal()
        })
      } else if (
        this.selectedRole === userRoles.MY_GUARDIAN ||
        this.selectedRole === userRoles.GUARDIAN_OF
      ) {
        let payload = {
          student_info_id: this.selectedUser?.id,
          guardian_info_id: this.selectedUser?.unassociated_id,
        }
        this.unAssociateStudentWithGuardian(payload).then((res) => {
          this.revokeEnitityHandler()
          this.forceRender++
          this.forceRenderSectionPagination++
          this.toggleRevokeAccessConfirmationModal()
        })
      }
    },
    toggleRevokeAccessConfirmationModal() {
      this.isOpen = !this.isOpen
    },
    revokeRoleAccess() {
      if (this.selectedUser?.unassociated_id) {
        this.unAssociateStudent()
      } else
        this.revokeUserEntityPermission(this.selectedUser.id).then((res) => {
          this.revokeEnitityHandler()
          this.$store.commit(
            'toast/NEW',
            { message: res?.data?.message, type: 'success' },
            { root: true },
          )
          this.forceRender++
          this.forceRenderSectionPagination++
          this.toggleRevokeAccessConfirmationModal()
        })
    },
    revokeEnitityHandler() {
      let campusAdminRecords =
        this.currentUser?.user_roles_details?.campus_admin_details?.records?.length
      let guardianRecords = this.currentUser?.user_roles_details?.guardian_details?.records?.length
      let myGuardianRecords =
        this.currentUser?.user_roles_details?.associated_guardians?.records?.length
      let staffRecords = this.currentUser?.user_roles_details?.staff_details?.records?.length
      let teacherRecords =
        this.currentUser?.user_roles_details?.section_teacher_details?.records?.length
      let pickupPersonRecords =
        this.currentUser?.user_roles_details?.pickup_person_details?.records?.length
      let myPickupPersonRecords =
        this.currentUser?.user_roles_details?.associated_pickup_persons?.records?.length
      let sectionAdminRecords =
        this.currentUser?.user_roles_details?.section_admin_details?.records?.length
      let OnlyUsersof = this.currentUser?.user_roles_details?.user_roles?.records?.length
      let studentRecords =
        this.currentUser?.user_roles_details?.section_student_details?.records?.length
      if (OnlyUsersof) this.getOnlyUserOfRecords()
      if (campusAdminRecords) this.getCampusAdminRecords()
      if (guardianRecords) {
        this.getGuardiansRecords()
        this.getOnlyUserOfRecords()
      }
      if (myGuardianRecords) this.getMyGuardiansOfRecords()
      if (staffRecords) this.getStaffRecords()
      if (teacherRecords) {
        this.getTeacherRecords()
        this.getOnlyUserOfRecords()
      }
      if (pickupPersonRecords) {
        this.getPickPersonRecords()
        this.getOnlyUserOfRecords()
      }
      if (myPickupPersonRecords) this.getMyPickPersonRecords()
      if (sectionAdminRecords) this.getSectionAdminRecords()
      if (studentRecords) {
        this.getStudentsRecords()
        this.getOnlyUserOfRecords()
      }

      this.getUserInfo()
    },
    getBorderClass(index) {
      return index === this.detailedList.length - 1
        ? ''
        : 'mb-5 border-b border-primary-gray-200 pb-5'
    },
    getGuardiansRecords(outerRange = {}, InnerRange = {}) {
      let payload = {
        id: this.currentUser?.user_details?.id,
        ...paginationRangeHandler(outerRange, this.maxRecordInOuterPagination),
        ...InnerRange,
      }
      this.isLoading = true
      this.getGuardianDetails(payload)
        .then((res) => {
          let records = res.data
          InnerRange.range
            ? this.setUserData(userRoles.GUARDIAN_OF, records, true)
            : this.setUserData(userRoles.GUARDIAN_OF, records)
        })
        .catch((error) => {
          this.setUserData(userRoles.GUARDIAN_OF, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getMyGuardiansOfRecords(range) {
      let query = {
        id: this.currentUser?.user_details?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getGuardiansOfDetails(query)
        .then((res) => {
          this.setUserData(userRoles.MY_GUARDIAN, res.data)
        })
        .catch((error) => {
          this.setUserData(userRoles.MY_GUARDIAN, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getStudentsRecords(outerRange = {}, InnerRange = {}) {
      let payload = {
        id: this.currentUser?.user_details?.id,
        ...paginationRangeHandler(outerRange, this.maxRecordInOuterPagination),
        ...InnerRange,
      }
      this.isLoading = true
      this.getSectionStudentDetails(payload)
        .then((res) => {
          let records = res.data
          InnerRange.range
            ? this.setUserData(userRoles.STUDENT, records, true)
            : this.setUserData(userRoles.STUDENT, records)
        })
        .catch((error) => {
          this.setUserData(userRoles.STUDENT, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getSectionAdminRecords(range) {
      let query = {
        id: this.currentUser?.user_details?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getSectionAdminDetails(query)
        .then((res) => {
          this.setUserData(userRoles.SECTION_ADMIN, res.data)
        })
        .catch((error) => {
          this.setUserData(userRoles.SECTION_ADMIN, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getPickPersonRecords(outerRange = {}, InnerRange = {}) {
      let payload = {
        id: this.currentUser?.user_details?.id,
        ...paginationRangeHandler(outerRange, this.maxRecordInOuterPagination),
        ...InnerRange,
      }
      this.isLoading = true
      this.getPickupPersonDetails(payload)
        .then((res) => {
          let records = res.data
          InnerRange.range
            ? this.setUserData(userRoles.PICKUP_PERSON_OF, records, true)
            : this.setUserData(userRoles.PICKUP_PERSON_OF, records)
        })
        .catch((error) => {
          this.setUserData(userRoles.PICKUP_PERSON_OF, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getMyPickPersonRecords(range) {
      let query = {
        id: this.currentUser?.user_details?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getMyPickupPersonDetails(query)
        .then((res) => {
          this.setUserData(userRoles.MY_PICKUP_PERSON, res.data)
        })
        .catch((error) => {
          this.setUserData(userRoles.MY_PICKUP_PERSON, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getStaffRecords(range) {
      let query = {
        id: this.currentUser?.user_details?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getStaffDetails(query)
        .then((res) => {
          this.setUserData(userRoles.STAFF, res.data)
        })
        .catch((error) => {
          this.setUserData(userRoles.STAFF, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getTeacherRecords(outerRange = {}, InnerRange = {}) {
      let payload = {
        id: this.currentUser?.user_details?.id,
        ...paginationRangeHandler(outerRange, this.maxRecordInOuterPagination),
        ...InnerRange,
      }
      this.isLoading = true
      this.getSectionTeacherDetails(payload)
        .then((res) => {
          let records = res.data
          InnerRange.range
            ? this.setUserData(userRoles.TEACHER, records, true)
            : this.setUserData(userRoles.TEACHER, records)
        })
        .catch((error) => {
          this.setUserData(userRoles.TEACHER, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getCampusAdminRecords(range) {
      let query = {
        id: this.currentUser?.user_details?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getCampusAdminDetails(query)
        .then((res) => {
          this.setUserData(userRoles.CAMPUS_ADMIN, res?.data)
        })
        .catch((error) => {
          this.setUserData(userRoles.CAMPUS_ADMIN, [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    // OnlyUsersof

    getOnlyUserOfRecords(range) {
      let query = {
        id: this.currentUser?.user_details?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getOnlyUserofDetails(query)
        .then((res) => {
          this.setUserData('ONLY_USER_OF', res?.data)
        })
        .catch((error) => {
          this.setUserData('ONLY_USER_OF', [])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setUserData(user, data, InnerRange = false) {
      let mutatedRecords = []
      switch (user) {
        case userRoles.GUARDIAN_OF:
          mutatedRecords = this.transformGuardianRecords(data?.records)
          break
        case userRoles.MY_GUARDIAN:
          mutatedRecords = this.transformMyGuardianRecords(data?.records)
          break
        case userRoles.CAMPUS_ADMIN:
          mutatedRecords = this.transformCampusAdminRecords(data?.records)
          break

        // OnlyUsersof
        case 'ONLY_USER_OF':
          mutatedRecords = this.transformOnlyUserOfRecords(data?.records)
          break
        case userRoles.TEACHER:
          mutatedRecords = this.transformTeacherRecords(data?.records)
          break
        case userRoles.STAFF:
          mutatedRecords = this.transformStaffRecords(data?.records)
          break
        case userRoles.PICKUP_PERSON_OF:
          mutatedRecords = this.transformPickupPersonRecords(data?.records)
          break
        case userRoles.MY_PICKUP_PERSON:
          mutatedRecords = this.transformMyPickupPersonRecords(data?.records)
          break
        case userRoles.SECTION_ADMIN:
          mutatedRecords = this.transformSectionAdminRecords(data?.records)
          break
        case userRoles.STUDENT:
          mutatedRecords = this.transformStudentRecords(data?.records)
          break
        default:
          mutatedRecords = []
          break
      }
      this.detailedList.forEach((listItem) => {
        if (listItem.title === user) {
          if (InnerRange) {
            listItem.records.forEach((record) => {
              if (record.campus.id === mutatedRecords[0].campus.id)
                record.classes_sections = mutatedRecords[0].classes_sections
            })
          } else {
            listItem.records = mutatedRecords
            listItem.recordsCount = data?.meta?.total_records
            {
              if (
                user === userRoles.GUARDIAN_OF ||
                user === userRoles.STUDENT ||
                user === userRoles.PICKUP_PERSON_OF ||
                user === userRoles.TEACHER
              ) {
                listItem.pagination = listItem.recordsCount > this.maxRecordInOuterPagination
              } else {
                listItem.pagination = listItem.recordsCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT
              }
            }
          }
        }
      })
    },
    transformGuardianRecords(records) {
      let guardianDetails = records.map((val) => {
        return {
          campus: {
            title: val?.campus?.title,
            pagination_id: val?.campus?.id,
            id: val?.campus?.entity_id,
          },

          classes_sections: val?.students?.records?.map((value) => {
            return {
              student:
                value?.association_detail?.first_name + ' ' + value?.association_detail?.last_name,
              class_section: value?.classes_sections?.map((item) => {
                return item?.section
                  ? (item?.class || GENERAL_CONSTANTS.NOT_APPLICABLE) +
                      ` / ` +
                      (item?.section || GENERAL_CONSTANTS.NOT_APPLICABLE)
                  : item?.class || GENERAL_CONSTANTS.NOT_APPLICABLE
              }),
              unassociated_id: value?.guardian_info_id,
              id: value?.student_info_id,
            }
          }),
          recordsCount: val?.students?.meta?.total_records,
          pagination: val?.students?.meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        }
      })
      return guardianDetails
    },
    transformMyGuardianRecords(records) {
      let guardianDetails = records.map((guardian) => {
        return {
          student: guardian?.first_name + ' ' + guardian?.last_name,
          relation: guardian?.relation,
          unassociated_id: guardian?.guardian_info_id,
          id: guardian?.student_info_id,
        }
      })
      return guardianDetails
    },
    transformCampusAdminRecords(records) {
      let campusDetails = records.map((val) => {
        return {
          campus: val?.title,
          id: val?.entity_id,
        }
      })
      return campusDetails
    },

    // user of

    transformOnlyUserOfRecords(records) {
      let userOfDetails = records.map((val) => {
        return {
          campus: val?.title,
          id: val?.entity_id,
          role: val?.role_id,
        }
      })
      return userOfDetails
    },

    transformTeacherRecords(records) {
      let teacherDetails = records.map((val) => {
        return {
          campus: {
            title: val?.campus?.title,
            pagination_id: val?.campus?.id,
            id: val?.campus?.entity_id,
          },
          classes_sections: val?.classes_sections?.records?.map((value) => {
            return {
              class: value?.class?.title,
              section: value?.section?.title,
              id: value?.id,
            }
          }),

          recordsCount: val?.classes_sections?.meta?.total_records,
          pagination:
            val?.classes_sections?.meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        }
      })
      return teacherDetails
    },
    transformStaffRecords(records) {
      let staffDetails = records.map((staff) => {
        return { title: staff?.title, id: staff.entity_id }
      })
      return staffDetails
    },
    transformPickupPersonRecords(records) {
      let pickupPersonDetails = records.map((val) => {
        return {
          campus: {
            title: val?.campus?.title,
            pagination_id: val?.campus?.id,
            id: val?.campus?.entity_id,
          },
          classes_sections: val?.students?.records?.map((value) => {
            return {
              student:
                value?.association_detail?.first_name + ' ' + value?.association_detail?.last_name,
              class_section: value?.classes_sections?.map((item) => {
                return item?.section
                  ? (item?.class || GENERAL_CONSTANTS.NOT_APPLICABLE) +
                      ` / ` +
                      (item?.section || GENERAL_CONSTANTS.NOT_APPLICABLE)
                  : item?.class || GENERAL_CONSTANTS.NOT_APPLICABLE
              }),
              unassociated_id: value?.pickup_person_info_id,
              id: value?.student_info_id,
            }
          }),
          recordsCount: val?.students?.meta?.total_records,
          pagination: val?.students?.meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        }
      })
      return pickupPersonDetails
    },
    transformMyPickupPersonRecords(records) {
      let pickupPersonDetails = records.map((pickupPerson) => {
        return {
          student: pickupPerson?.first_name + ' ' + pickupPerson?.last_name,
          relation: pickupPerson?.relation,
          unassociated_id: pickupPerson?.pickup_person_info_id,
          id: pickupPerson?.student_info_id,
        }
      })
      return pickupPersonDetails
    },
    transformSectionAdminRecords(records) {
      let sectionAdminDetails = records.map((sectionAdmin) => {
        return {
          campus: sectionAdmin?.campus?.title,
          class: sectionAdmin?.class?.title,
          section: sectionAdmin?.section?.title,
        }
      })
      return sectionAdminDetails
    },
    transformStudentRecords(records) {
      let studentDetails = records.map((val) => {
        return {
          campus: {
            title: val?.campus?.title,
            pagination_id: val?.campus?.id,
            id: val?.campus?.entity_id,
          },

          classes_sections: val?.classes_sections?.records?.map((value) => {
            return {
              class: value?.class?.title,
              section: value?.section?.title,
              id: value?.id,
            }
          }),
          recordsCount: val?.classes_sections?.meta?.total_records,
          pagination:
            val?.classes_sections?.meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        }
      })

      return studentDetails
    },
    setCampusAdminData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.CAMPUS_ADMIN,
        description: 'User is the campus admin of following campuses',
        tableHead: ['Campus', 'Actions'],
        records: this.transformCampusAdminRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        getRecords: (range) => {
          this.getCampusAdminRecords(range)
        },
      })
    },

    // user of
    setOnlyUserOfData({ records, meta }) {
      this.detailedList.push({
        title: 'ONLY_USER_OF',
        description: 'Only user of following campuses with respective roles',
        tableHead: ['Campus', 'Role', 'Actions'],
        records: this.transformOnlyUserOfRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        getRecords: (range) => {
          this.getOnlyUserOfRecords(range)
        },
      })
    },

    setTeacherData({ records, meta }) {
      let showPagination = meta?.total_records > this.maxRecordInOuterPagination
      this.detailedList.push({
        title: userRoles.TEACHER,
        description: 'User is the teacher in following campuses',
        tableHead: ['Class', 'Section', 'Actions'],
        records: this.transformTeacherRecords(records),
        recordsCount: meta?.total_records,
        pagination: showPagination || 0,
        class: true,
        getRecords: (range) => {
          this.getTeacherRecords(range)
        },
        getSectionRecords: (range, id = '') => {
          const innerRange = {
            $where: {
              ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', id || this.currentCampusScope?.id),
            },
            range,
          }
          this.getTeacherRecords({}, innerRange)
        },
      })
    },
    setGuardianData({ records, meta }) {
      let showPagination = meta?.total_records > this.maxRecordInOuterPagination
      this.detailedList.push({
        title: userRoles.GUARDIAN_OF,
        description: 'User is the guardian of following students',
        tableHead: ['Student Name', 'Class / Section', 'Actions'],
        records: this.transformGuardianRecords(records),
        recordsCount: meta?.total_records,
        class: true,
        pagination: showPagination || 0,
        getRecords: (range) => {
          this.getGuardiansRecords(range)
        },
        getSectionRecords: (range, id = '') => {
          const innerRange = {
            $where: {
              ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', id || this.currentCampusScope?.id),
            },
            range,
          }
          this.getGuardiansRecords({}, innerRange)
        },
        chipFlag: ['class_section'],
      })
    },
    setMyGuardianData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.MY_GUARDIAN,
        description: 'User has following guardians',
        tableHead: ['Guardian Name', 'Relation', 'Actions'],
        records: this.transformMyGuardianRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        getRecords: (range) => {
          this.getMyGuardiansOfRecords(range)
        },
      })
    },
    setStaffData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.STAFF,
        description: 'User is the staff in following campuses',
        tableHead: ['Campus', 'Actions'],
        records: this.transformStaffRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        getRecords: (range) => {
          this.getStaffRecords(range)
        },
      })
    },
    setPickupPersonData({ records, meta }) {
      let showPagination = meta?.total_records > this.maxRecordInOuterPagination
      this.detailedList.push({
        title: userRoles.PICKUP_PERSON_OF,
        description: 'User is the pickup person of following students',
        tableHead: ['Student Name', 'Class / Section', 'Actions'],
        records: this.transformPickupPersonRecords(records),
        recordsCount: meta?.total_records,
        class: true,
        pagination: showPagination || 0,
        getRecords: (range) => {
          this.getPickPersonRecords(range)
        },

        getSectionRecords: (range, id = '') => {
          const innerRange = {
            $where: {
              ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', id || this.currentCampusScope?.id),
            },
            range,
          }
          this.getPickPersonRecords({}, innerRange)
        },
        chipFlag: ['class_section'],
      })
    },
    setMyPickupPersonData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.MY_PICKUP_PERSON,
        description: 'User has following pickup persons',
        tableHead: ['Pickup Person Name', 'Relation', 'Actions'],
        records: this.transformMyPickupPersonRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        getRecords: (range) => {
          this.getMyPickPersonRecords(range)
        },
      })
    },
    setSectionAdminData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.SECTION_ADMIN,
        description: 'User is the section admin in following campuses',
        tableHead: ['Campus', 'Class', 'Section', 'Actions'],
        records: this.transformSectionAdminRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
        getRecords: (range) => {
          this.getSectionAdminRecords(range)
        },
      })
    },
    setStudentData({ records, meta }) {
      let showPagination = meta?.total_records > this.maxRecordInOuterPagination
      this.detailedList.push({
        title: userRoles.STUDENT,
        description: 'User is the student in following campuses',
        tableHead: ['Class', 'Section', 'Actions'],
        records: this.transformStudentRecords(records),
        recordsCount: meta?.total_records,
        pagination: showPagination || 0,
        class: true,
        getRecords: (range) => {
          this.getStudentsRecords(range)
        },
        getSectionRecords: (range, id = '') => {
          const innerRange = {
            $where: {
              ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', id || this.currentCampusScope?.id),
            },
            range,
          }
          this.getStudentsRecords({}, innerRange)
        },
      })
    },
    setRecords(value = '', recordArray = []) {
      return value && Array.isArray(value) ? value : recordArray.push(value)
    },
    roleDetail() {
      this.currentUser = objectDeepCopy(this.user)
      let isSuperAdmin = this.currentUser?.user_details?.is_super_admin
      let campusAdminRecords =
        this.currentUser?.user_roles_details?.campus_admin_details?.records?.length
      let guardianRecords = this.currentUser?.user_roles_details?.guardian_details?.records?.length
      let myGuardianRecords =
        this.currentUser?.user_roles_details?.associated_guardians?.records?.length
      let staffRecords = this.currentUser?.user_roles_details?.staff_details?.records?.length
      let teacherRecords =
        this.currentUser?.user_roles_details?.section_teacher_details?.records?.length
      let pickupPersonRecords =
        this.currentUser?.user_roles_details?.pickup_person_details?.records?.length
      let myPickupPersonRecords =
        this.currentUser?.user_roles_details?.associated_pickup_persons?.records?.length
      let OnlyUsersof = this.currentUser?.user_roles_details?.user_roles?.records?.length
      let sectionAdminRecords =
        this.currentUser?.user_roles_details?.section_admin_details?.records?.length
      let studentRecords =
        this.currentUser?.user_roles_details?.section_student_details?.records?.length
      if (OnlyUsersof) this.setOnlyUserOfData(this.currentUser?.user_roles_details?.user_roles)
      if (campusAdminRecords && !this.currentCampusScope)
        this.setCampusAdminData(this.currentUser?.user_roles_details?.campus_admin_details)
      if (guardianRecords)
        this.setGuardianData(this.currentUser?.user_roles_details?.guardian_details)
      if (myGuardianRecords)
        this.setMyGuardianData(this.currentUser?.user_roles_details?.associated_guardians)
      if (staffRecords && !this.currentCampusScope)
        this.setStaffData(this.currentUser?.user_roles_details?.staff_details)
      if (teacherRecords)
        this.setTeacherData(this.currentUser?.user_roles_details?.section_teacher_details)
      if (pickupPersonRecords)
        this.setPickupPersonData(this.currentUser?.user_roles_details?.pickup_person_details)
      if (myPickupPersonRecords)
        this.setMyPickupPersonData(this.currentUser?.user_roles_details?.associated_pickup_persons)
      if (sectionAdminRecords)
        this.setSectionAdminData(this.currentUser?.user_roles_details?.section_admin_details)
      if (studentRecords)
        this.setStudentData(this.currentUser?.user_roles_details?.section_student_details)
    },
    getUserInfo() {
      let query = {
        id: this.currentUser?.user_details?.id,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.currentUser.user_details = res?.data
        })
        .catch((error) => {
          this.currentUser = null
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    filteredEntries(data) {
      return Object.entries(data).filter(([key, value]) => key !== 'id')
    },
    ...mapActions('staff', [
      'getPickupPersonDetails',
      'getGuardianDetails',
      'getSectionStudentDetails',
      'getSectionTeacherDetails',
      'getSectionAdminDetails',
      'getCampusAdminDetails',
      'getOnlyUserofDetails',
      'getUserDetails',
      'getStaffDetails',
    ]),
    ...mapActions('pickup', ['unAssosiateStudentsWithPickupMembers']),
    ...mapActions('student', ['unAssociateStudentWithGuardian']),

    ...mapActions('users', [
      'updateUserRoleAccess',
      'getStudentClassesSections',
      'getTeacherClassesSections',
      'getGuardianStudents',
      'getPickupPersonStudents',
      'getGuardiansOfDetails',
      'getMyPickupPersonDetails',
      'revokeUserEntityPermission',
    ]),
  },
}
</script>

<style lang="scss" module>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>

<style lang="scss" scoped>
.w-inherit {
  width: inherit;
}
table td:last-child,
table th:last-child {
  width: 40px;
  > span {
    border: none;
  }
}
.record-table th:first-child,
.record-table td:first-child {
  width: 40px;
}
</style>
