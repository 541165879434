<template>
  <div>
    <div v-if="!isLoading">
      <TitleWrapper
        :filter-option="false"
        title="ROLES_AND_ACCESS"
        tooltip-title="ROLES_AND_ACCESS"
        :display-breadcrumb="true"
      />
      <UserRoleAndAccessView
        :user="userDetail"
        :is-not-open-in-modal="true"
        @filter="getUserInfo"
      />
    </div>
    <div v-else>
      <Loader class="mt-20 mb-40" :content="true" />
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import UserRoleAndAccessView from '@/src/components/userRoleAndAccessView.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'

export default {
  components: {
    TitleWrapper,
    UserRoleAndAccessView,
    Loader,
  },
  data() {
    return {
      isLoading: false,
      userDetail: null,
      userId: this.$route.params.id,
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo(data) {
      let queryParam = {
        skip: data?.range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: data?.range?.limit || GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
      }
      let query = {
        id: this.userId,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          res.data.student_info = {}
          res.data.student_info.id = res.data.student_id
          this.userDetail = res.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    ...mapActions('users', ['getUserDetails']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
